<template>
  <div class="h-screen flex w-full bg-img vx-row no-gutter items-center justify-center" id="page-login">
    <div class="vx-col sm:w-1/2 md:w-1/2 lg:w-3/4 xl:w-3/5 sm:m-0 m-4">
      <vx-card>
        <div slot="header">
          <h3>
            Hello world !
          </h3>
        </div>
        <div>
          <span>The quick brown fox jumps over the lazy dog.</span>
        </div>
        <div slot="footer">
          <div class="vx-row" vx-justify="flex-end">
            <div class="vx-button" type="gradient" color="danger" icon="favorite">Test</div>
            <div class="vx-button" type="gradient" color="primary" icon="turned_in_not">Test</div>
            
          </div>
        </div>
      </vx-card>
    </div>
  </div>
  
</template>

<script>
export default {

}
</script>

<style>

</style>